import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { NotFoundBlock } from '@lerna-monorepo/common/components/notFoundBlock/notFoundBlock.component';
import { LanguageSlug } from '@lerna-monorepo/common/enums/languageSlug.enum';

import { PageNotFoundData } from './404.types';

const PageNotFound: React.FC<PageNotFoundData> = (pageData: PageNotFoundData) => {
  const {
    header,
    description,
    buttonLabel,
    notFoundImage,
   } = pageData.pageContext.data.notFoundPage;
  const languageCode = pageData.location.pathname.split('/')[1];
  
  useEffect(() => {
    const languageCodes = [
      String(LanguageSlug.De),
      String(LanguageSlug.En),
      String(LanguageSlug.Ru),
    ];
    const path = languageCodes.includes(languageCode)
      ? `/${languageCode}/404`
      : '/404';
    navigate(path, { replace: false });
  }, []);

  return (
    <NotFoundBlock
      header={header}
      description={description}
      buttonLabel={buttonLabel}
      notFoundImage={notFoundImage}
      langCode={languageCode}
    />
  );
};

export default PageNotFound;
